import { NgModule, NO_ERRORS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AgmCoreModule } from '@agm/core';
import { CalendarModule } from 'angular-calendar';
import { SharedModule } from '../shared/shared.module';
import { FooterComponent } from '../main-layout/footer/footer.component';
import { LoginComponent } from './login/login.component';
import { RegisterComponent } from './register/register.component';
import { SetPasswordComponent } from './set-password/set-password.component';
import { UserRegisterComponent } from './user-register/user-register.component';
import  {  PdfViewerModule  }  from  'ng2-pdf-viewer';
import { ShopifyComponent } from './shopify/shopify.component';
import { ShopifyAddProductComponent } from './shopify-add-product/shopify-add-product.component';
import { ShopifyUpdateProductComponent } from './shopify-update-product/shopify-update-product.component';
import { UproductViewComponent } from './uproduct-view/uproduct-view.component';




@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    BrowserModule,
    BrowserAnimationsModule,
    SharedModule,
    PdfViewerModule,
    AgmCoreModule.forRoot({
  
      apiKey: 'AIzaSyCb44fZMVNTqsA7phK5chbOolMgsJl9mFw'
    }),
    CalendarModule.forRoot()
  ],
  declarations: [
    FooterComponent,
    LoginComponent,
    RegisterComponent,
    SetPasswordComponent,  
    UserRegisterComponent,  
    ShopifyComponent,   
    ShopifyAddProductComponent,
    ShopifyUpdateProductComponent,
    UproductViewComponent, 
  ],
  exports: [
    FooterComponent,
    LoginComponent,
    RegisterComponent,   
    SetPasswordComponent,
   
  ],
  schemas: [NO_ERRORS_SCHEMA]
})
export class ViewsModule { }

import {
  Component,
  OnInit
} from '@angular/core';
import {
  FormGroup,
  FormBuilder,
  Validators
} from '@angular/forms';
import {Router, ActivatedRoute} from '@angular/router';
import {
  AngularFireAuth
} from 'angularfire2/auth';

import {
  Observable,
  Subscription
} from 'rxjs';
import {
  User
} from 'firebase';



import { LoginServiceService } from 'src/app/services/login/login-service.service';

export {
  User
}
from 'firebase';
import {
  CookieService
} from 'ngx-cookie-service';

@Component({
  selector: 'app-set-password',
  templateUrl: './set-password.component.html',
  styleUrls: ['./set-password.component.scss']
})
export class SetPasswordComponent implements OnInit {
  loginForm: FormGroup;
  submitted: boolean;
  public authState: User = null;
  private user: User;
  private sub: Subscription;
  error: string;
  progress: boolean;
  errorMessage: string;
  currentUser: any = [];
  showLoader: boolean;
  hide: boolean = true;
  cryptojsService: any;
  decryptData: any;
  setpasswordForm: FormGroup;
  resetPassword: FormGroup;
  successMessage: string;
  success: string; 
  setpasswordFormhide: number=1;
  passDivShow: number=0;
  userid: any;
  constructor(
    private formBuilder: FormBuilder,
    private router: Router,
    private route: ActivatedRoute,
    public afAuth: AngularFireAuth,   
    private loginServiceService: LoginServiceService,    
    public cookieService: CookieService

  ) {}

  ngOnInit() {
    this.showLoader = false;
    setTimeout(() => {
      this.showLoader = true;
    }, 2000);
    this.setpasswordForm = this.formBuilder.group({
      set_pass_email: ['', [Validators.required, Validators.email]],
    });
    this.resetPassword = this.formBuilder.group({
      newPassword: ['', [Validators.required,Validators.minLength(6)]],
      confirmPassword: ['', [Validators.required,Validators.minLength(6)]],
     
    });
    this.sub = this.authState$.subscribe(user => {

      this.user = user;
      //  console.log(user)

    });


  }

  private showError(error: string) {

    this.error = error;
    this.progress = false;
     setTimeout(() => this.error = null, 2000);
  }

  get authenticated() {
    return this.user !== null;
  }


  get authState$(): Observable < User | null > {
    return this.afAuth.user;
  }
  get f() {
    return this.resetPassword.controls;
  }
  get pa() {
    return this.setpasswordForm.controls;
  }
 

  private reportSuccess(message: string, jumpTo ? : string) {

    this.progress = false;
    //this.report.add(message);

    if (jumpTo) {

      this.router.navigate([jumpTo])

    }
  }

 

  ngOnDestroy() {
    this.sub.unsubscribe();

  }
  onSubmitassword(){
    
    // stop here if form is invalid
    if (this.resetPassword.invalid) {
      return;
    }else{
       if(this.f.newPassword.value === this.f.confirmPassword.value){
            setTimeout(() => {
              
              this.showLoader = true;
              const value = this.newPassword(this.f.newPassword.value);
          }, 2000);
        
        
       }else{
        this.errorMessage = "Passwords do not match.";
        this.showError(this.errorMessage);
       }


    }
   
   
  }
  onSubmit() {
    this.submitted = true;

    // stop here if form is invalid
    if (this.setpasswordForm.invalid) {
      return;
    }else{
          setTimeout(() => {
          
            this.showLoader = true;
          const value = this.forgotPassword(this.pa.set_pass_email.value);
        }, 2000);
    }
    
    
  }
  public forgotPassword(email: string) {
    

   // console.log(email);
    let logindata={ results: [{"email":email}]};
    //  console.log(details);
      this.loginServiceService.resetPassword(logindata)
      .subscribe((loginuser: any) => {
        setTimeout(() => {
         // console.log(loginuser);
          this.showLoader = true;
          if(loginuser.response== 200 && loginuser.status==true ){
             this.setpasswordFormhide=0;
             this.passDivShow=1;
             //email_address user_id

             this.userid=loginuser.reset[0]['data'][0]['user_id'];
             console.log(this.userid);
          }else{
           // rajeevpathak121@gmail.com
            this.setpasswordFormhide=1;
            this.passDivShow=0;
          }
        }, 5000);

      }
    );
   

    
  }
  public newPassword(newPassword: string) {
    

    // console.log(email);
     let logindata={ results: [{"newPassword":newPassword,"userid":this.userid}]};
    console.log(logindata);
       this.loginServiceService.updatePassword(logindata)
       .subscribe((loginuser: any) => {
         setTimeout(() => {
          // console.log(loginuser);
           this.showLoader = true;
           if(loginuser.response== 200 && loginuser.status==true ){
            this.reportSuccess('Signed in as ' + newPassword, 'login')
              //console.log(loginuser);
           }else{
               console.log(loginuser);
           }
         }, 5000);
 
       }
     );
    
 
     
   }
}

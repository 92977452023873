import { ModalComponent } from './components/modal/modal.component';
import { MDBBootstrapModulesPro } from 'ng-uikit-pro-standard';
import { NgModule, NO_ERRORS_SCHEMA, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AlertComponent } from './alerts/alert/alert.component';
import { CascadingPanelComponent } from './components/cascading-panel/cascading-panel.component';
import { CascadingCardComponent } from './components/cascading-card/cascading-card.component';
import { OverlayCardComponent } from './components/overlay-card/overlay-card.component';
import { PanelComponent } from './components/panel/panel.component';
import { NotificationComponent } from './components/notification/notification.component';
import { NotificationService } from './components/notification/notification.service';
import { ListFilterPipe } from './filter-pipe/list-filter.pipe';
import { LoaderComponent } from './loader/loader.component';
import {MDBSpinningPreloader} from 'ng-uikit-pro-standard';
import { CurrencyPipe } from './currency-pipe/currency.pipe';
import { CalculateEmiPipe } from './calculate-emi-pipe/calculate-emi.pipe';
import { FileuploadsComponent } from './fileuploads/fileuploads.component';

@NgModule({
  imports: [
    CommonModule,
    MDBBootstrapModulesPro.forRoot(),
  ],
  declarations: [
    AlertComponent,
    CascadingPanelComponent,
    CascadingCardComponent,
    OverlayCardComponent,
    PanelComponent,
    ModalComponent,
    NotificationComponent,
   LoaderComponent,
    ListFilterPipe,
    CurrencyPipe,
    CalculateEmiPipe,
    FileuploadsComponent
    
   

  ],
  exports: [
    MDBBootstrapModulesPro,
    CascadingPanelComponent,
    CascadingCardComponent,
    OverlayCardComponent,
    PanelComponent,
    ModalComponent,
    NotificationComponent,
    ListFilterPipe,
    LoaderComponent,
    CurrencyPipe,
    CalculateEmiPipe,
    FileuploadsComponent

  ],
  providers: [
    NotificationService,
   MDBSpinningPreloader

  ],
  schemas: [NO_ERRORS_SCHEMA, CUSTOM_ELEMENTS_SCHEMA]
})
export class SharedModule { }

import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ShopifyService } from 'src/app/services/shopify/shopify.service';
import { ActivatedRoute, Router } from '@angular/router';
@Component({
  selector: 'app-shopify-update-product',
  templateUrl: './shopify-update-product.component.html',
  styleUrls: ['./shopify-update-product.component.scss']
})
export class ShopifyUpdateProductComponent implements OnInit {
  showLoader: boolean;
  submitted: boolean = false;
  selectedFile: File | null = null;
  registerForm: FormGroup;
  sub: any;
  data: any;
  productid: any;
  description: any;
  image_url: any;
  modified_on: any;
  product_id: any;
  product_type: any;
  quantitity: any;
  sku_id: any;
  title: any;
  variantid: string;
  vendor: any;
  tabval: number;

  constructor( private formBuilder: FormBuilder, private shopifyService : ShopifyService,
    private route:ActivatedRoute,   
    private router:Router,
  ) { }

  ngOnInit() {
    this.showLoader = false;
    setTimeout(()=>{    
          this.showLoader = true;
    }, 2000);
    this.sub = this.route
    .queryParams
    .subscribe(params => {
      //console.log(params.id); 
    
        this.data = params.id;  
        if(this.data != undefined){
                 
             this.productid=this.data;
        }else{
             
          this.router.navigate(['/Product-List']);
        }        
      },(err)=>{
        console.log(err);
      });

      this.shopifyService.editProduct(this.productid).subscribe((editlist:any) => {
        
        if(editlist.response == 200 && editlist.status == true){
         // console.log(editlist.ctwa[0]['data'][0]);
             const editdata=editlist.ctwa[0]['data'][0];
             this.description=editdata.description;
             const imgurl= "C:/uploads/";
             this.image_url=imgurl+editdata.image_url;
             this.modified_on=editdata.modified_on;
             this.product_id=editdata.product_id;
             this.product_type=editdata.product_type;
             this.quantitity=editdata.quantitity;
             this.sku_id=editdata.sku_id;
             this.title=editdata.title;
             this.variantid=editdata.variant_id;
             this.vendor=editdata.vendor;
           //console.log(this.variantid);
        }
      
      });
      this.registerForm = this.formBuilder.group({
        addform: this.formBuilder.group({
          variant_id: ['', Validators.required],
          title: ['', Validators.required],
          product_type: ['', Validators.required],
          description: ['', Validators.required],
          vendors: ['', Validators.required],
          sku_id: ['', Validators.required],
          quantitity: ['', Validators.required],
          file: ['', Validators.required]
          
          
         }),
           
      });
  }
 
  
  submittedAdd = true;
  get sd() { return (<FormGroup>this.registerForm.get('addform')).controls; }
  get addform() {
    return this.registerForm.get('addform');
  }
  selectFile(event) {
    const files = event.target.files;
    if (files.length > 0) {
      this.selectedFile = files[0];
      //console.log(this.selectedFile);
    }
  }
  onSubmit(){
  
    this.submitted = true;
    
    if (this.addform.invalid) {
      return;
      //console.log(this.addform.value);
      //console.log("invalid");
    }else{
     // console.log(this.selectedFile); 
     // console.log(this.addform.value.file);       //return false; 
      const formData = new FormData();
      //formData.append('file', this.addform.value.file);
     formData.append('file', this.selectedFile); 
      formData.append('title', this.addform.value.title);
      formData.append('variant_id', this.addform.value.variant_id);
      formData.append('product_type', this.addform.value.product_type);
      formData.append('description', this.addform.value.description);
      formData.append('vendors', this.addform.value.vendors);
      formData.append('sku_id', this.addform.value.sku_id);
      formData.append('quantitity', this.addform.value.quantitity);
      formData.append('productid', this.productid);
      formData.append('shopify_id', this.productid);
      //console.log(this.selectedFile); 
      //console.log(formData);      // return false;     
    //  details['results']; 
     // console.log(details);       //return false; 
        this.shopifyService.updateProducts(formData)
        .subscribe((results: any) => {
          
          if(results.response== 200 && results.status == true){
            this.showLoader = false;
            setTimeout(()=>{    
              this.showLoader = true;
              this.router.navigate(['/Product-List']);
            }, 2000);
  
          }else{
            this.tabval = 1;
           // console.log("error");       return false;  
          }
          //console.log(results);       return false;   
        }
      );
     
    }
  }
  backmenu(){
    this.showLoader = false;
    setTimeout(()=>{    
      //this.showLoader = true;
      this.router.navigate(['/Product-List']);
    }, 2000);
   
    
    }
}

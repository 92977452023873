import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class TokenService {
   
   // *local  token
    bearer_token:string = "Bearer    6adc0ddd-7e0d-4677-ab79-2217196cdf9b";
   //* CRM token
      //bearer_token:string = "Bearer    1682f6e1-7584-41a6-a43b-86d2a011ee74";
   //* uat token
     //bearer_token:string = "Bearer  ea11f43c-44ec-4725-84e3-c46e2b277d9b";
     

     

  
  constructor() { }
}
    





import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Global } from 'src/app/global/global';
import { Observable } from 'rxjs';
import { TokenService } from 'src/app/services/token/token.service';
import { CookieService } from 'ngx-cookie-service';
@Injectable({
  providedIn: 'root'
})
@Injectable({
  providedIn: 'root'
})
export class LoginServiceService {

  constructor(
    private cookieService :CookieService,
    private http: HttpClient,
    public global:Global,
    private TokenService:TokenService,
  ) { }
  bearer_token = this.TokenService.bearer_token ;
  // login api end point
  resetPassword(data){
  
    let headers = new HttpHeaders().set('Content-Type', 'application/json')
     .set('Authorization', this.bearer_token).set('Access-Control-Allow-Origin','*');
     return this.http.post<any>(`${this.global.apiurl}`+'signIn/reset',{data:data},{headers: headers});
 
   }
   
   updatePassword(data){
  
    let headers = new HttpHeaders().set('Content-Type', 'application/json')
     .set('Authorization', this.bearer_token).set('Access-Control-Allow-Origin','*');
     return this.http.post<any>(`${this.global.apiurl}`+'signIn/updatePassword',{data:data},{headers: headers});
 
   }
  loginData(data){
  
    let headers = new HttpHeaders().set('Content-Type', 'application/json')
     .set('Authorization', this.bearer_token).set('Access-Control-Allow-Origin','*');
     return this.http.post<any>(`${this.global.apiurl}`+'signIn/login',{data:data},{headers: headers});
 
   }
   createUser(data){
  
    let headers = new HttpHeaders().set('Content-Type', 'application/json')
     .set('Authorization', this.bearer_token).set('Access-Control-Allow-Origin','*');
     return this.http.post<any>(`${this.global.apiurl}`+'signIn/createUser',{data:data},{headers: headers});
 
   }

   
   verifyMoileNumer(mno){
    //console.log(data);
    let headers = new HttpHeaders().set('Content-Type', 'application/json')
    .set('Authorization', this.bearer_token).set('Access-Control-Allow-Origin','*');   
    return this.http.get<Global>(`${this.global.apiurl}`+'signIn/verifyMoileNumer/'+mno+'',{headers:headers});
   // console.log(url);
  }

   userData() {
    const User = this.cookieService.get('currentUser');
   // console.log(User);
    if(User != undefined){
      return User;
      
    }else{
      return false;
    }
    
  }
  getDetailedDataEntry() {
    const moile = this.cookieService.get('detailedDataEntry');
    //console.log(moile);
    if(moile != undefined){
      return moile;
      
    }else{
      return false;
    }
    
  }
  userlogin(mno){
    //console.log(data);
    let headers = new HttpHeaders().set('Content-Type', 'application/json')
    .set('Authorization', this.bearer_token).set('Access-Control-Allow-Origin','*');   
    return this.http.get<Global>(`${this.global.apiurl}`+'signIn/userSignin/'+mno+'',{headers:headers});
   // console.log(url);
  }
  registerUserList(){
    let headers = new HttpHeaders().set('Content-Type', 'application/json')
  .set('Authorization', this.bearer_token).set('Access-Control-Allow-Origin','*');   
  return this.http.get<Global>(`${this.global.apiurl}`+'admin/registerUserList',{headers:headers});
  }
  getUserRole(){
    let headers = new HttpHeaders().set('Content-Type', 'application/json')
  .set('Authorization', this.bearer_token).set('Access-Control-Allow-Origin','*');   
  return this.http.get<Global>(`${this.global.apiurl}`+'admin/getUserRole',{headers:headers});
  }


  
}

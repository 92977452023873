import { Injectable } from '@angular/core';
@Injectable()
export class Global{
    // accessControlOrigin(arg0: string, accessControlOrigin: any) {
     
    //   //throw new Error("Method not implemented.");
    // }
    accessControlOrigin="*";
    // *Local
      //apiurl:string = 'http://localhost:5000/v1/';  
   //*uat
      //apiurl:string = 'https://java-api.meraemi.in/uat/v1/'; 
   //*crm
     apiurl:string = 'https://api2.meraemi.com/crm/v1/';
     
   
}
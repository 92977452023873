import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-fileuploads',
  templateUrl: './fileuploads.component.html',
  styleUrls: ['./fileuploads.component.scss']
})
export class FileuploadsComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}

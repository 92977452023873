import { Component, OnInit } from '@angular/core';
import { AngularFireAuth } from 'angularfire2/auth';
import { Router } from '@angular/router';
import { Subscription, Observable } from 'rxjs';
import { AuthenticationService } from 'src/app/services/authentication/authentication.service';
import { CookieService } from 'ngx-cookie-service';

import { LoginServiceService } from 'src/app/services/login/login-service.service';



@Component({
  selector: 'app-navigation',
  templateUrl: './navigation.component.html',
  styleUrls: ['./navigation.component.scss']
})
export class NavigationComponent implements OnInit {
  
  user:any;
  sub: Subscription;

  isValue: number=0;
  followupStageCount: any=0;
  PendingfeedbackCount: any=0;
  IApPendingfeedbackCount: any=0;
  EligPendingfeedbackCount: any=0;
  currentdate: string;
  userdate: string;
  elements: any=[];
  arrayelement: any=[];
  elements_elig: any=[];
  elig_Pendingfeedback_elements: any=[];
  iap_Pendingfeedback_elements: any=[];
  follow_update: any;
  stage_color: string;
  appstage: string;
  urlstage: string;
  elig_status: string;
  follwup_elig: any=0;
  provider: string;
  lender_name: string;
  element_main: any=[];
  iap_pendingCount:number=0;
  getContactRequest:number=0;
  
  totalUserSelfEmp: number=0;
  totalUserProfessional: number=0;
  totalpartner: number=0;
  rolecode: any;
  loginAdmin: any;
  adminname: any;
  ctwadatalength: number=0;
  userlistLength: number=0;
  loginuser: number=0;
  fname: any;
  lname: any;
  element_assign: any=[];
  cityid: any;
  loginuserid: any;
  elementsSal: any=[];
  saldata: number=0;
  elementsSelfEmp:any=[];
  elementsSelfEmpTotal: number=0;
  elementsSelfEmpProfessional:any=[];
  elemSelfEmpProfTotal: number=0;
  eleCtwaUser:any=[];
  eleCtwaUsertotal: number=0;
  fbAdid: any;
  elemLeadCtwa:any=[];
  elemLeadCtwaTotal: number=0;
  totalpertnerForm: number=0;
  constructor(
    public afAuth: AngularFireAuth,
    public router:Router,
    
    private authService:AuthenticationService,
    public cookieService:CookieService,
    

    private loginServiceService: LoginServiceService,
  
  
   
    ) { }

  ngOnInit() {
 
   
    this.user=this.loginServiceService.userData();
    //console.log('Cookie Value 2:',  this.user.length);
   // console.log('Cookie Value 2:', JSON.parse(this.user)['rolecode']);
   if(this.user.length !=0){
    this.cityid=(JSON.parse(this.user)['cityname']);
    this.loginuserid= (JSON.parse(this.user)['fb_uid']);
    this.rolecode= (JSON.parse(this.user)['rolecode']);
    this.fname= (JSON.parse(this.user)['first_name']);
    this.lname= (JSON.parse(this.user)['last_name']); 
   }else{
    this.router.navigate(['login']);
   }

    
  
  
  

  }
  toggle1() { 
    this.isValue = 1;
     
  }
  toggle2() { 
    this.isValue = 2;
    
  }
  toggle3() { 
    this.isValue = 3; 
    
  }
  
  get authenticated() {
    
    return this.user ;
    
  }
  rotate(event){
    event.srcElement.classList.remove("rotate");
    event.srcElement.classList.remove("tooltiptext");
    setTimeout(()=>{
      event.srcElement.classList.add("rotate");
      event.srcElement.classList.remove("tooltiptext");
    location.reload();
    },0)
  }
  mis_user(event){   
    event.srcElement.classList.remove("tooltiptext");
    setTimeout(()=>{      
      event.srcElement.classList.remove("tooltiptext");
      location.reload();
    },0)
  }
  support(event){   
    this.router.navigate(['/send-email']);  
    event.srcElement.classList.remove("tooltiptext");  
    setTimeout(()=>{ 
      this.router.navigate(['/send-email']) ;  
      event.srcElement.classList.remove("tooltiptext");  
      
      location.reload();
    },0)
  }
  chat(event){   
    this.router.navigate(['/send-email']);  
    event.srcElement.classList.remove("tooltiptext");  
    setTimeout(()=>{ 
      this.router.navigate(['/send-email']) ;  
      event.srcElement.classList.remove("tooltiptext");  
      
      location.reload();
    },0)
  }
  // get authState$(): Observable<User|null> {
  //   return this.afAuth.user;
  // }
  logout(){
    this.afAuth.auth.signOut();
    
 //   localStorage.removeItem('currentUser');
   // localStorage.removeItem('token_rm');
    this.cookieService.delete('currentUser');
    this.cookieService.delete('token_rm');
    this.router.navigate(['login']);

  }

}

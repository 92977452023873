import { Injectable } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { Global } from 'src/app/global/global';
import { TokenService } from 'src/app/services/token/token.service';
@Injectable({
  providedIn: 'root'
})
export class ShopifyService {
  message: any;
 
  constructor(
    private http: HttpClient,
    private cookieService: CookieService,
    public global:Global,
    private TokenService:TokenService,
  ) { }
  bearer_token = this.TokenService.bearer_token ;
 
   postProducts(data: FormData) {
    // Assuming data is FormData containing file and other fields
    let headers = new HttpHeaders()
      .set('Authorization', this.bearer_token);
  
    return this.http.post<any>(`${this.global.apiurl}shopify/addProduct`, data, { headers: headers });
  }
   getAllproduct(){
    let headers = new HttpHeaders().set('Content-Type', 'application/json')
    .set('Authorization', this.bearer_token).set('Access-Control-Allow-Origin','*');   
    return this.http.get<Global>(`${this.global.apiurl}`+'shopify/getAllproduct',{headers:headers});
  }
  editProduct(data){  
    //console.log(docid);   
    let headers = new HttpHeaders().set('Content-Type', 'application/json')
    .set('Authorization', this.bearer_token).set('Access-Control-Allow-Origin','*');   
    return this.http.get<Global>(`${this.global.apiurl}`+'shopify/editProduct/'+data+'',{headers:headers});    
  }

   updateProducts(data: FormData) {
    // Assuming data is FormData containing file and other fields
    let headers = new HttpHeaders()
      .set('Authorization', this.bearer_token);
  
    return this.http.post<any>(`${this.global.apiurl}shopify/updateProducts`, data, { headers: headers });
  }
   editquantitity(data){  
    
    let headers = new HttpHeaders().set('Content-Type', 'application/json')
     .set('Authorization', this.bearer_token).set('Access-Control-Allow-Origin','*');
     return this.http.post<any>(`${this.global.apiurl}`+'shopify/editquantitity',{data:data},{headers: headers});
     
   }
  
}

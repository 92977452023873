import { MdbTablePaginationComponent, MdbTableDirective,ModalDirective, IMyOptions, MDBDatePickerComponent } from 'ng-uikit-pro-standard';
import { Component, OnInit, ViewChild, HostListener, AfterViewInit, ChangeDetectorRef} from '@angular/core';

import { Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';




import { LoginServiceService } from 'src/app/services/login/login-service.service';
import { ShopifyService } from 'src/app/services/shopify/shopify.service';
import { EditorComponent  } from '@tinymce/tinymce-angular';
export interface Color {
  name: string;
}
@Component({
  selector: 'app-shopify-add-product',
  templateUrl: './shopify-add-product.component.html',
  styleUrls: ['./shopify-add-product.component.scss']
})
export class ShopifyAddProductComponent implements OnInit, AfterViewInit {

  @ViewChild(MdbTablePaginationComponent, { static: true }) mdbTablePagination: MdbTablePaginationComponent;
  @ViewChild(MdbTableDirective, { static: true }) mdbTable: MdbTableDirective
  submitted: boolean = false;
  selectedFile: File | null = null;
  registerForm: FormGroup;
  
  fileuploads: boolean;
  registerForms: any;
  showLoader: boolean;
  tabval: number;
  imgSrc: string;
   
  constructor( 
      
      private formBuilder: FormBuilder,
      private router: Router,
      private cdRef: ChangeDetectorRef,
      private loginServiceService: LoginServiceService,
      
      private shopifyService : ShopifyService
      
      ) { }
    ngAfterViewInit(): void {
      //throw new Error('Method not implemented.');
    }
    ngOnInit() {
      this.fileuploads = true;
      this.showLoader = false;
      setTimeout(() => {
        this.showLoader = true;
      }, 5000);
    
      this.registerForm = this.formBuilder.group({
        addform: this.formBuilder.group({
          variant_id: ['', Validators.required],
          title: ['', Validators.required],
          product_type: ['', Validators.required],
          description: ['', Validators.required],
          vendors: ['', Validators.required],
          sku_id: ['', Validators.required],
          quantitity: ['', Validators.required],
          file: ['', Validators.required]
          
          
        }),
          
      });
    

      

      
    }
    onFileChange(event: any) {
      // 'event' parameter will contain information about the change, such as the files that were selected
      console.log('Files changed:', event.target.files);
      
      // You can then perform any necessary actions with the files, such as uploading or processing them
      // For example:
      // this.uploadFiles(event.target.files);
    }
  submittedAdd = true;
  get sd() { return (<FormGroup>this.registerForm.get('addform')).controls; }
  get addform() {
    return this.registerForm.get('addform');
  }
  selectFile(event) {
    const files = event.target.files;
    if (files.length > 0) {
      this.selectedFile = files[0];
      this.imgSrc = URL.createObjectURL(event.target.files[0]);
      console.log(this.imgSrc);
    }
  }
  onSubmit(){
    
    this.submitted = true;
    if (this.addform.invalid) {
      return;
      //console.log(this.addform.value);
      //console.log("invalid");
    }else{
      //console.log(this.selectedFile); 
    // console.log(this.addform.value.file);       //return false; 
      const formData = new FormData();
      //formData.append('file', this.addform.value.file);
      formData.append('file', this.selectedFile); 
      formData.append('title', this.addform.value.title);
      formData.append('variant_id', this.addform.value.variant_id);
      formData.append('product_type', this.addform.value.product_type);
      formData.append('description', this.addform.value.description);
      formData.append('vendors', this.addform.value.vendors);
      formData.append('sku_id', this.addform.value.sku_id);
      formData.append('quantitity', this.addform.value.quantitity);
      //console.log(this.addform.value.quantitity); 
    // console.log(formData);      // return false;     
    //  details['results']; 
    // console.log(details);       //return false; 
        this.shopifyService.postProducts(formData)
        .subscribe((results: any) => {
          
          if(results.response== 200 && results.status == true){
        

            this.fileuploads = false;
            setTimeout(()=>{    
              this.fileuploads = true;
              this.tabval = 1;
              this.router.navigate(['/Product-List']);
            }, 5000);
          }else{
            this.tabval = 1;
          //  console.log("error");       return false;  
          }
        // console.log(results);       return false;   
        }
      );
    
    }
  }
  backmenu(){
    this.showLoader = false;
    setTimeout(()=>{    
      //this.showLoader = true;
      this.router.navigate(['/Product-List']);
    }, 2000);
  
    
  }
}
import { MdbTablePaginationComponent, MdbTableDirective,ModalDirective, IMyOptions, MDBDatePickerComponent } from 'ng-uikit-pro-standard';
import { Component, OnInit, ViewChild, HostListener, AfterViewInit, ChangeDetectorRef} from '@angular/core';

import { Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

import { LoginServiceService } from 'src/app/services/login/login-service.service';
import { ShopifyService } from 'src/app/services/shopify/shopify.service';
export interface Color {
  name: string;
}
@Component({
  selector: 'app-shopify',
  templateUrl: './shopify.component.html',
  styleUrls: ['./shopify.component.scss']
})
export class ShopifyComponent implements OnInit, AfterViewInit {
  @ViewChild(MdbTablePaginationComponent, { static: true }) mdbTablePagination: MdbTablePaginationComponent;
  @ViewChild(MdbTableDirective, { static: true }) mdbTable: MdbTableDirective
  @ViewChild("edit", {static: true}) edit: ModalDirective;
  @ViewChild("alert", {static: true}) alert: ModalDirective;
  searchText: any;
  elements: any = [];
  previous: any = [];
  showLoader: boolean;
  registerForm: FormGroup;
 
  headElements = [
    
    {
      name:"View",
      sortBy:"View"
    } 
     ,
    
    {
       name:"Product id",
       sortBy:"Product id"
     }
     ,
    
     
     
     {
       name:"Variant Id",
       sortBy:"Variant Id",
     },
     
    
     {
       name:"SKU ID",
       sortBy:"SKU ID"
     },
     
     {
       name:"Title",
       sortBy:"Title"
     },
     
     {
       name:"Product Type",
       sortBy:"Product Type"
     },
     
     
     {
       name:"Description",
       sortBy:"Description"
     },
    
     
     
     {
       name:"Vendor ",
       sortBy:"Vendor"
     },
     {
      name:"Status",
      sortBy:"Status"
    }
     ,
     {
      name:"Date",
      sortBy:"Date"
    },
    
     
     {
       name:"Quantitity",
       sortBy:"Quantitity"
     }
     
    
     

   ];
   shopify_id: any;
 
  quantitity: any;
  editquantitity: String=null;
  
  constructor( 
    private formBuilder: FormBuilder,
    private router: Router,
    private cdRef: ChangeDetectorRef,
    private loginServiceService: LoginServiceService,
    
    private shopifyService : ShopifyService   
      
      ) { }

  ngOnInit() {
    this.showLoader = false;
   
    this.registerForm = this.formBuilder.group({

      
      editformgup: this.formBuilder.group({
        quantitity: ['', Validators.required]
      
    }),
      
    });
    this.shopifyService
    .getAllproduct()
    .subscribe( (result:any) => {
      //console.log(result); return false;
      setTimeout(() => {
        this.showLoader = true;
          if (result.status = true  && result.response=== 200) {      //        
            for (let i = 0; i < result.product.length;  i++) {
              const element = result.product[i];
              const imageUrl = element.data[0].image_url; // Make sure this is the correct file path

              this.elements.push(
                {
                  description	:element.data[0].description,
                  image_url	:imageUrl,
                  modified_on: element.data[0].modified_on,
                  product_id: element.data[0].product_id,            
                  product_type: element.data[0].product_type,
                  quantitity	:element.data[0].quantitity,             
                  sku_id: element.data[0].sku_id,
                  status: element.data[0].status,
                  title: element.data[0].title,
                  variant_id: element.data[0].variant_id,
                  vendor: element.data[0].vendor,
                  shopify_id: element.data[0].shopify_id,
                }
                );
                
            // console.log(element); //return false;
            }
            this.mdbTable.setDataSource(this.elements);
                this.elements = this.mdbTable.getDataSource();
                this.previous = this.mdbTable.getDataSource();
          }
        }, 5000);
    
    });
         
   
    
    
  }
 
 
   submittedEdit = true;
   get ed() { return (<FormGroup>this.registerForm.get('editformgup')).controls; }
   get editformgup() {
     return this.registerForm.get('editformgup');
   }
    //intraktUrlAPIlisting
   
  onDisplayValue(color: Color): string | undefined {
    //console.log(color);
    return color ? color.name : undefined;
  }
 
 
 
 

  ngAfterViewInit() {
    this.mdbTablePagination.setMaxVisibleItemsNumberTo(10);

    this.mdbTablePagination.calculateFirstItemIndex();
    this.mdbTablePagination.calculateLastItemIndex();
    this.cdRef.detectChanges();
  }
 
  searchItems() {
    const prev = this.mdbTable.getDataSource();

    if (!this.searchText) {
      this.mdbTable.setDataSource(this.previous);
      this.elements = this.mdbTable.getDataSource();
    }

    if (this.searchText) {
      //console.log(this.searchText);
      this.elements = this.mdbTable.searchLocalDataBy(this.searchText);
      //console.log(this.elements);
      this.mdbTable.setDataSource(prev);
      //console.log(this.mdbTable);
    }
  }
   //filter
 
 
   editQuantitity(data){
    //console.log(data); return false;
    this.quantitity    = data.quantitity;
    this.shopify_id  = data.shopify_id;
   
   
    
    this.showLoader = false;
    setTimeout(() => {
    this.edit.show();
      this.showLoader = true;
    }, 1000);
   
  }

  editbtn(){
    this.submittedEdit = true;
    this.showLoader = false;
  
    // stop here if form is invalid
    if (this.editformgup.invalid) {
      //console.log("err");
      setTimeout(() => {
        this.showLoader = true;
      }, 2000);
  
      return;
    }else{
      this.showLoader = false;
      setTimeout(() => {
      this.alert.show();
        this.showLoader = true;
      }, 1000);
      this.editquantitity =this.editformgup.value.quantitity;
      this.edit.hide();
    }
  }
  alertsub(){
    this.showLoader = false;
    //console.log(this.editquantitity);
    let quantitity=this.editformgup.value.quantitity.toString();
   
     
    let detail = { results: [{ "quantitity":quantitity,"shopify_id":this.shopify_id}] };
    detail['results']; 
  
   this.shopifyService.editquantitity(detail).subscribe((update: any) => {
   // console.log(update);
    if(update.response == 200 && update.status == true){
      setTimeout(() => {
        this.alert.show();
          this.showLoader = true;
      this.alert.hide();
      this.elements=[];
      this.shopifyService
      .getAllproduct()
      .subscribe( (result:any) => {
        //console.log(result); return false;
        if (result.status = true  && result.response=== 200) {      //        
          for (let i = 0; i < result.product.length;  i++) {
            const element = result.product[i];
            const imageUrl = element.data[0].image_url; // Make sure this is the correct file path
  
            this.elements.push(
              {
                description	:element.data[0].description,
                image_url	:imageUrl,
                modified_on: element.data[0].modified_on,
                product_id: element.data[0].product_id,            
                product_type: element.data[0].product_type,
                quantitity	:element.data[0].quantitity,             
                sku_id: element.data[0].sku_id,
                status: element.data[0].status,
                title: element.data[0].title,
                variant_id: element.data[0].variant_id,
                vendor: element.data[0].vendor,
                shopify_id: element.data[0].shopify_id,
              }
              );
              
            //console.log(element); //return false;
          }
          this.mdbTable.setDataSource(this.elements);
              this.elements = this.mdbTable.getDataSource();
              this.previous = this.mdbTable.getDataSource();
        }
      
      });
    }, 1000);

      
    }


  });
  }

  viewdetails(data){
    console.log(data);
      
    const productid=data.shopify_id;
           
      const base_url=location.origin+"/#/view-Product?id=";        
      const detailsurl = base_url + productid.toString();
     
     
      //console.log(detailsurl);
      window.open(detailsurl, "_blank");
  
  // console.log('ok');
  }

  

  viewProduct(data){
    //
    const productid=data.shopify_id;
           
    const base_url=location.origin+"/#/update-Product?id=";        
    const detailsurl = base_url + productid.toString();
   
   
    //console.log(detailsurl);
    window.open(detailsurl, "_blank");
   }





        
     
     
  
       
          
backmenu(){
  this.showLoader = false;
  setTimeout(()=>{    
    //this.showLoader = true;
    this.router.navigate(['/Product-List']);
  }, 2000);
 
  
  }
}
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ShopifyService } from 'src/app/services/shopify/shopify.service';
import { ActivatedRoute, Router } from '@angular/router';
@Component({
  selector: 'app-uproduct-view',
  templateUrl: './uproduct-view.component.html',
  styleUrls: ['./uproduct-view.component.scss']
})
export class UproductViewComponent implements OnInit {
  submitted: boolean = false;
  selectedFile: File | null = null;
  registerForm: FormGroup;
  sub: any;
  data: any;
  productid: any;
  description: any;
  image_url: any;
  modified_on: any;
  product_id: any;
  product_type: any;
  quantitity: any;
  sku_id: any;
  title: any;
  variant_id: any;
  vendor: any;
  showLoader: boolean;
  constructor( private formBuilder: FormBuilder, private shopifyService : ShopifyService,
    private route:ActivatedRoute,   
    private router:Router,
  ) { }

  ngOnInit() {
 
    this.sub = this.route
    .queryParams
    .subscribe(params => {
      //console.log(params.id); 
    
        this.data = params.id;  
        if(this.data != undefined){
                 
             this.productid=this.data;
        }else{
             
          this.router.navigate(['/shopify']);
        }        
      },(err)=>{
        console.log(err);
      });
      this.showLoader = false;
      setTimeout(() => {
        this.showLoader = true;
      this.shopifyService.editProduct(this.productid).subscribe((editlist:any) => {
        console.log(editlist);
        if(editlist.response == 200 && editlist.status == true){
          console.log(editlist);
             const editdata=editlist.ctwa[0]['data'][0];
                this.description=editdata.description;
                this.image_url=editdata.image_url;
                this.modified_on=editdata.modified_on;
                this.product_id=editdata.product_id;
                this.product_type=editdata.product_type;
                this.quantitity=editdata.quantitity;
                this.sku_id=editdata.sku_id;
                this.title=editdata.title;
                this.variant_id=editdata.variant_id;
                this.vendor=editdata.vendor;
             

        }
      ;
      });
    }, 5000);
 
  }
  onFileSelected(event: any) {
    this.selectedFile = event.target.files[0];
  }
  
 
 
}

import {ToastModule} from 'ng-uikit-pro-standard';
import {BrowserModule} from '@angular/platform-browser';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {NgModule,  CUSTOM_ELEMENTS_SCHEMA} from '@angular/core';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {RouterModule} from '@angular/router';
import {AppComponent} from './app.component';
import {AppRoutes} from './app.routes.service';

import {ViewsModule} from './views/views.module';
import {SharedModule} from './shared/shared.module';
import {MDBSpinningPreloader} from 'ng-uikit-pro-standard';
import {AgmCoreModule} from '@agm/core';

// main layout
import { NavigationModule } from './main-layout/navigation/navigation.module';
import { AuthGuard } from './guards/auth.guard';
import { Global } from './global/global';
import { HttpClientModule } from '@angular/common/http';
import { CookieService } from 'ngx-cookie-service';
import { AuthenticationService } from './services/authentication/authentication.service';
import { AngularFireModule } from 'angularfire2';
import { environment } from '../environments/environment';
import { AngularFireAuthModule } from 'angularfire2/auth';
import { AngularFirestoreModule } from '@angular/fire/firestore';
import { EditorModule } from '@tinymce/tinymce-angular';

import { AngularFireDatabaseModule } from 'angularfire2/database';
import { SendEmailComponent } from './email/send-email/send-email.component';



@NgModule({
    declarations: [
        AppComponent,
        SendEmailComponent,
        
        
    ],
    imports: [
        EditorModule,
        BrowserModule,
        BrowserAnimationsModule,
        NavigationModule,
        AppRoutes,
        RouterModule,
        FormsModule,
        SharedModule,
        ViewsModule,
        ToastModule.forRoot(),
        HttpClientModule,
        ReactiveFormsModule,
        AngularFireModule.initializeApp(environment.firebase),
        AngularFireAuthModule,
        AngularFirestoreModule,
        AngularFireDatabaseModule,
           
        AgmCoreModule.forRoot({
            apiKey: 'your key here'
        })
    ],
    providers: [AuthGuard,MDBSpinningPreloader,Global,CookieService,AuthenticationService ],
    bootstrap: [AppComponent],
    schemas: [ CUSTOM_ELEMENTS_SCHEMA]
})
export class AppModule {
}

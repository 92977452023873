import {RouterModule, Route} from '@angular/router';
import {ModuleWithProviders} from '@angular/core';
import { AuthGuard } from './guards/auth.guard';
import { LoginComponent } from './views/login/login.component';
import { RegisterComponent } from './views/register/register.component';
import { SetPasswordComponent } from './views/set-password/set-password.component';
import { ShopifyComponent } from './views/shopify/shopify.component';
import { ShopifyUpdateProductComponent } from './views/shopify-update-product/shopify-update-product.component';
import { ShopifyAddProductComponent } from './views/shopify-add-product/shopify-add-product.component';
import { UproductViewComponent } from './views/uproduct-view/uproduct-view.component';


const routes: Route[] = [
    {path: '', pathMatch:'full', redirectTo:'dashboard'},    
    {path: 'register', component: RegisterComponent},
    {path: 'login', component: LoginComponent},
    {path: 'set-password', component: SetPasswordComponent},
    {path: 'Product-List', component:   ShopifyComponent, canActivate:[AuthGuard]},
   
    // {path: 'shopify', component:   ShopifyComponent, canActivate:[AuthGuard]},
    {path: 'add-Product', component:   ShopifyAddProductComponent, canActivate:[AuthGuard]},
    {path: 'update-Product', component:   ShopifyUpdateProductComponent, canActivate:[AuthGuard]},
    // {path: 'shopifyUpdateProduct', component:   ShopifyUpdateProductComponent, canActivate:[AuthGuard]},
    {path: 'view-Product', component:   UproductViewComponent, canActivate:[AuthGuard]},
   // {path: 'productView', component:   UproductViewComponent, canActivate:[AuthGuard]},
    
    
];

export const AppRoutes: ModuleWithProviders = RouterModule.forRoot(routes,{useHash: true});
